import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { triangle } from 'polished'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { colors, radius, space } from 'theme'

const StyledBox = styled(Box)`
  padding: ${({ tooltipContainerPadding }) => tooltipContainerPadding};
  background-color: ${colors.white};
  box-shadow: 0px 4px 15px rgba(39, 40, 49, 0.1);
  border-radius: ${radius.l};
`

const Triangle = styled.div`
  ${triangle({ pointingDirection: 'bottom', width: '14px', height: '11px', foregroundColor: 'white' })}
`

const TooltipWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  position: absolute;
  z-index: 20;
  top: ${({ top }) => top || '-55px'};
  left: ${({ left }) => left || 0};
  right: ${({ right }) => right || 0};
  visibility: hidden;
`

const StyledFlex = styled(Flex)`
  :hover ${TooltipWrapper} {
    visibility: visible;
  }
`

const StyledText = styled(Text)`
  text-align: center;
  width: ${({ width }) => width || '250px'};
`

const IconCompWrapper = styled(Flex)`
  ${({ isCursorPointer }) =>
    isCursorPointer &&
    `
    cursor: pointer;
  `}
`

const Tooltip = ({
  message,
  contentComp: ContentComp,
  iconComp: IconComp,
  wrapperComp: WrapperComp,
  onClick,
  alignItems,
  left,
  right,
  width,
  isTriangleVisible,
  isCursorPointer,
  defaultTopOffset,
  tooltipContainerPadding,
}) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [topOffset, setTopOffset] = useState(0)
  const tooltipWrapperRef = useRef(null)

  useEffect(() => {
    if (tooltipWrapperRef && tooltipWrapperRef.current) {
      setTopOffset(tooltipWrapperRef.current.clientHeight)
    }
  }, [windowWidth])

  useEffect(() => {
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <StyledFlex onClick={onClick} position="relative">
      {WrapperComp && WrapperComp}
      {IconComp && (
        <IconCompWrapper isCursorPointer={isCursorPointer}>
          <IconComp />
        </IconCompWrapper>
      )}
      {(ContentComp || message) && (
        <TooltipWrapper top={-topOffset - defaultTopOffset} left={left} right={right} alignItems={alignItems}>
          <StyledBox ref={tooltipWrapperRef} padding={tooltipContainerPadding}>
            {ContentComp && ContentComp}
            {message && <StyledText width={width}>{message}</StyledText>}
          </StyledBox>
          {isTriangleVisible && <Triangle />}
        </TooltipWrapper>
      )}
    </StyledFlex>
  )
}

Tooltip.defaultProps = {
  isTriangleVisible: true,
  defaultTopOffset: 6,
  tooltipContainerPadding: space.s,
}

Tooltip.propTypes = {
  message: PropTypes.string,
  contentComp: PropTypes.node,
  iconComp: PropTypes.any,
  wrapperComp: PropTypes.node,
  onClick: PropTypes.func,
  alignItems: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  isTriangleVisible: PropTypes.bool,
  isCursorPointer: PropTypes.bool,
  defaultTopOffset: PropTypes.number,
  tooltipContainerPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Tooltip
